import Index from "views/Index.jsx";
import Profile from "views/examples/Profile.jsx";
import Maps from "views/examples/Maps.jsx";
import Register from "views/examples/Register.jsx";
import Login from "pages/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Icons from "views/examples/Icons.jsx";
import Matches from "./pages/dashboard/matches/Matches";
import CreateMatch from "./pages/dashboard/matches/CreateMatch";
import Users from "./pages/dashboard/users/Users";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    hidden: true,
    component: Index,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    hidden: true,
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    hidden: true,
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },

  {
    path: "/user-profile",
    hidden: true,
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/matches/:id?",
    link: "/matches",
    name: "Matches",
    icon: "ni ni-bullet-list-67 text-red",
    component: Matches,
    layout: "/admin"
  },
  {
    path: "/create-match",
    name: "Create Match",
    icon: "ni ni-single-02 text-yellow",
    component: CreateMatch,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    hidden: true,
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    hidden: true,
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    hidden: true,
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
];
export default routes;
