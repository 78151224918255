import React from "react";
import axios from "../../../axios-orders";
import cookie from "react-cookies";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import { Link } from "react-router-dom";
import Header from "components/Headers/Header.jsx";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Alert,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal
} from "reactstrap";
// core components

class Match extends React.Component {
  constructor(props) {
    super(props);
    let token = cookie.load("session");
    this.state = { token, isBusy: false, editJoin: false };
    this.token = token;
    this.onUpdateEditJoin = this.onUpdateEditJoin.bind(this);
    this.getJoinUserList = this.getJoinUserList.bind(this);
    this.handleUpdateForm = this.handleUpdateForm.bind(this);
    this.updateJoinEntry = this.updateJoinEntry.bind(this);
    this.getJoinUserList();
  }

  getJoinUserList() {
    axios({
      method: "GET",
      url: "join/match/" + this.props.match.id,
      headers: { Authorization: this.token }
    })
      .then(data => {
        let newState = { ...this.state, joinedUsers: data.data.join };
        this.setState(newState);
      })
      .catch(error => {
        console.log("errro", error);
      });
  }

  onUpdateEditJoin(event) {
    this.setState({
      ...this.state,
      editJoin: {
        ...this.state.editJoin,
        [event.target.name]: event.target.value
      }
    });
  }

  handleUpdateForm(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    console.log(formData);
    var data = {};
    formData.forEach(function(value, key) {
      data[key] = value;
    });
    this.setState({ ...this.state, isBusy: true });
    this.updateJoinEntry(data);
  }
  updateJoinEntry(data) {
    axios({
      method: "post",
      url: "join/update",
      data: JSON.stringify(data),
      headers: { Authorization: this.token }
    })
      .then(data => {
        this.getJoinUserList();
        this.setState({ ...this.state, editJoin: false, isBusy: false });
      })
      .catch(error => {
        let errorMsg = error.response.data.message;
        if (!errorMsg) {
          errorMsg = "Something Wrong while connecting. Try Again Later.";
        }
        this.setState({
          ...this.state,
          isBusy: false,
          error: errorMsg
        });
      });
  }

  render() {
    let searchWord = this.state.searchWord;

    let joinedUsers = this.state.joinedUsers
      ? this.state.joinedUsers.filter(join => {
          let condition = true;
          if (searchWord) {
            condition =
              (searchWord.startsWith("id:") &&
                String(join.id).includes(searchWord.replace("id:", ""))) ||
              (searchWord.startsWith("user_id:") &&
                String(join.user_id).includes(
                  searchWord.replace("user_id:", "")
                )) ||
              (searchWord.startsWith("pubg_name:") &&
                String(join.pubg_name).includes(
                  searchWord.replace("pubg_name:", "")
                )) ||
              (searchWord.startsWith("kills:") &&
                String(join.kills).includes(
                  searchWord.replace("kills:", "")
                )) ||
              (searchWord.startsWith("winning_prize:") &&
                String(join.winning_prize).includes(
                  searchWord.replace("winning_prize:", "")
                )) ||
              (searchWord.startsWith("rank:") &&
                String(join.rank).includes(searchWord.replace("rank:", ""))) ||
              (searchWord.startsWith("winner:") &&
                String(join.winner).includes(
                  searchWord.replace("winner:", "")
                )) ||
              (searchWord.startsWith("active:") &&
                String(join.active).includes(
                  searchWord.replace("active:", "")
                )) ||
              (searchWord.startsWith("winning_date:") &&
                String(join.winning_date).includes(
                  searchWord.replace("winniwinning_dateng_prize:", "")
                )) ||
              (searchWord.startsWith("created_at:") &&
                String(join.created_at).includes(
                  searchWord.replace("created_at:", "")
                ));
            if (!condition) {
              condition = JSON.stringify(join).includes(searchWord);
            }
          }
          return condition;
        })
      : [];

    const columns = [
      {
        dataField: "pubg_name",
        text: "Game Name",

        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },
      {
        dataField: "user_id",
        align: "center",
        text: "User Id",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },

      {
        dataField: "kills",
        align: "center",
        text: "Kills",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },
      {
        dataField: "winning_prize",
        text: "Prize Winned",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },
      {
        dataField: "rank",
        align: "center",
        text: "Rank",
        sort: true,
        style: {
          textTransform: "capitalize "
        }
      },
      {
        dataField: "winner",
        text: "Winner",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize "
        },
        formatter: (cell, row) => {
          return <span>{cell == 1 ? <i class="fas fa-check" /> : ""}</span>;
        }
      },
      {
        dataField: "active",
        text: "Active",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize "
        },
        formatter: (cell, row) => {
          return <span>{cell == 1 ? <i class="fas fa-check" /> : ""}</span>;
        }
      },
      {
        dataField: "created_at",
        text: "Joined Time",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },

      {
        text: "Actions",

        align: "center",
        sort: true,
        formatter: (cell, row) => {
          return (
            <>
              <span
                className="text-success cursor-pointer"
                onClick={() => {
                  this.setState({ ...this.state, editJoin: row });
                }}
              >
                <i className="far fa-edit" />
              </span>
            </>
          );
        }
      }
    ];
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-default  shadow card">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col md="3">
                      <h3 className="text-white mb-0">Joined Users</h3>
                    </Col>
                    <Col md="9">
                      <Form className="navbar-search navbar-search-dark justify-content-end form-inline mr-3 d-none d-md-flex ml-lg-auto">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            onChange={e => {
                              this.setState({
                                ...this.state,
                                searchWord: e.target.value
                              });
                            }}
                            type="text"
                          />
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardHeader>

                <BootstrapTable
                  bordered={false}
                  classes="align-items-center table-dark table-flush table"
                  headerClasses="thead-dark table-header-center"
                  keyField="id"
                  data={joinedUsers}
                  wrapperClasses="table-responsive"
                  columns={columns}
                  responsive
                  pagination={paginationFactory()}
                />
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered "
          isOpen={this.state.editJoin}
          toggle={() => this.setState({ ...this.state, editJoin: false })}
        >
          <div className="modal-header bg-default">
            <h3
              className="modal-title capitalize text-white"
              id="exampleModalLabel"
            >
              {this.state.editJoin.name}
            </h3>
            <Button
              aria-label="Close"
              color="primary"
              className="text-white"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ ...this.state, editJoin: false })}
            >
              <i class="fas fa-times" />
            </Button>
          </div>
          <div className="modal-body bg-default">
            <div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white capitalize text-center mb-0">
                    {this.state.editJoin.pubg_name}
                  </h3>
                  {this.state.error ? (
                    <Alert color="danger" className="text-center">
                      {this.state.error}
                    </Alert>
                  ) : null}
                </CardHeader>
                <CardBody className="px-lg-5">
                  <Form onSubmit={this.handleUpdateForm} role="form">
                    <Row>
                      <Col lg="12">
                        <Input
                          name="id"
                          value={this.state.editJoin.id}
                          type="hidden"
                        />
                        <FormGroup>
                          <label className="form-control-label">Kills</label>
                          <Input
                            required
                            value={this.state.editJoin.kills}
                            className="form-control-alternative"
                            placeholder="kills"
                            onChange={this.onUpdateEditJoin}
                            name="kills"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Winner</label>
                          <select
                            required
                            onChange={this.onUpdateEditJoin}
                            value={this.state.editJoin.winner}
                            name="winner"
                            className="form-control-alternative form-control"
                          >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Winning Prize
                          </label>
                          <Input
                            value={
                              this.state.editJoin.kills *
                                this.props.match.kill_prize +
                              this.state.editJoin.winner *
                                this.props.match.winning_prize
                            }
                            required
                            className="form-control-alternative"
                            placeholder="Winning Prize"
                            name="winning_prize"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Active</label>
                          <select
                            required
                            onChange={this.onUpdateEditJoin}
                            value={this.state.editJoin.active}
                            name="active"
                            className="form-control-alternative form-control"
                          >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        disabled={this.state.isBusy}
                        type="submit"
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="modal-footer bg-default">
            <Button
              color="primary"
              data-dismiss="modal"
              className="w-100"
              type="button"
              onClick={() => this.setState({ ...this.state, editJoin: false })}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Match;
