import React from "react";
import axios from "../../../axios-orders"
import cookie from "react-cookies";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Alert,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";

class CreateMatch extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    let token = cookie.load("session");
    this.state = { token };
    this.token = token;

    this.handleSubmit = this.handleSubmit.bind(this)
    this.addMatch = this.addMatch.bind(this)
  }

  handleSubmit(event){
    event.preventDefault();
    const formData = new FormData(event.target);
    console.log(formData);
    var data = {};
    formData.forEach(function(value, key){
      data[key] = value;
          });
    this.setState({...this.state,isBusy:true})
    this.addMatch(data);
  }
  addMatch(data){
    axios({
      method: "post",
      url: "Matches",
      data: JSON.stringify(data),
      headers: { Authorization:  this.token }
    })
      .then(data => {
        this.props.history.goBack();
        this.setState({ ...this.state, isBusy: false });
      })
      .catch(error => {
        let errorMsg = error.response.data.message;
        if (!errorMsg) {
          errorMsg = "Something Wrong while connecting. Try Again Later.";
        }
        this.setState({
          ...this.state,
          isBusy: false,
          error: errorMsg 
        });
      });
  }
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="justify-content-center">
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h2 className="mb-0 text-center text-primary">
                        Create Match
                      </h2>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Username"
                              name="name"
                              type="text"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <select
                            required
                              name="type"
                              className="form-control-alternative form-control"
                            >
                              <option value="Solo">Solo</option>
                              <option value="Duo">Duo</option>
                              <option value="Four">Four</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Version
                            </label>
                            <select
                            required
                              name="version"
                              className="form-control-alternative form-control"
                            >
                              <option value="FPP">FPP</option>
                              <option value="TPP">TPP</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Map</label>
                            <select
                            required
                              name="map"
                              className="form-control-alternative form-control"
                            >
                              <option value="Erangel">Erangel</option>
                              <option value="TPP">TPP</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Match Schedule
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="22 Mar, 2019 08:15 PM"
                              required
                              name="start_time"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Prize Information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Match Type
                            </label>
                            <select
                            required
                              name="match_type"
                              className="form-control-alternative form-control"
                            >
                              <option value="Paid">Paid</option>
                              <option value="Free">Free</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Entry Fee
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Entry Fee"
                              required
                              name="entry_fee"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Winning Prize
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Winning Prize"
                              required
                              name="winning_prize"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Kill Prize
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Kill Prize"
                              required
                              name="kill_prize"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Active Match
                            </label>
                            <select
                            required
                              name="active"
                              className="form-control-alternative form-control"
                            >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="text-center">
                      <Button
                        className="my-4 w-100"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                    {this.state.error ? (
                <Alert color="danger" className="text-center">
                  {this.state.error}
                </Alert>
              ) : null}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CreateMatch;
