import React from "react";
import cookie from "react-cookies";
import axios from "../axios-orders";
// reactstrap components
import {
  Button,
  Card,
  Alert,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      errors: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.loginUser = this.loginUser.bind(this);
  }
  onSubmit(event) {
    event.preventDefault();
    let formData = {
      pubg_name: event.target.pubg_name.value,
      password: event.target.password.value
    };
    this.setState({ ...this.state, errors: {}, isBusy: true });
    this.loginUser(formData);
  }

  loginUser(userData) {
    axios
      .post("/user/login", userData)
      .then(data => {
        if (data.status === 200) {
          let userData = data.data.user;
          if (userData.role === "Admin") {
            cookie.save("user", userData, { path: "/" });
            cookie.save("session", data.data.token, { path: "/" });
            this.props.history.push("/admin/users");
          } else {
            this.setState({
              ...this.state,
              isBusy: false,
              errors: { message: "You are not admin" }
            });
          }
        }
      })
      .catch(error => {
        let errorMsg = error.response.data.message;
        if (!errorMsg) {
          errorMsg = "Something Wrong while connecting. Try Again Later.";
        }
        this.setState({
          ...this.state,
          isBusy: false,
          errors: { message: errorMsg }
        });
      });
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              {this.state.errors.message ? (
                <Alert color="danger" className="text-center">
                  {this.state.errors.message}
                </Alert>
              ) : null}
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form onSubmit={this.onSubmit} role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="PUBG Name" name="pubg_name" required />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      name="password"
                      required
                      type="password"
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
