import React from "react";
import axios from "../../../axios-orders";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Header from "components/Headers/Header.jsx";
// reactstrap components
import {
  Card,
  CardHeader,
  Form,
  InputGroup,
  Button,
  Col,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Input,
  Progress,
  Table,
  Container,
  Row
} from "reactstrap";

// core components

class Users extends React.Component {
  constructor(props) {
    super(props);
    let token = cookie.load("session");
    this.state = { token, modelMatch: false };
    this.token = token;
    console.log(this.token);
    this.getMatchList = this.getMatchList.bind(this);
    this.getMatchList();
  }

  getMatchList() {
    axios({
      method: "GET",
      url: "/user/list",
      headers: { Authorization: this.token }
    })
      .then(data => {
        let newState = { ...this.state, users: data.data.users };
        this.setState(newState);
      })
      .catch(error => {
        console.log("errro", error);
      });
  }
  render() {
    let searchWord = this.state.searchWord;

    let users = this.state.users
      ? this.state.users.filter(user => {
          let condition = true;
          if (searchWord) {
            condition =
              (searchWord.startsWith("id:") &&
                String(user.id).includes(searchWord.replace("id:", ""))) ||
              (searchWord.startsWith("first_name:") &&
                String(user.first_name).includes(
                  searchWord.replace("first_name:", "")
                )) ||
              (searchWord.startsWith("last_name:") &&
                String(user.last_name).includes(
                  searchWord.replace("last_name:", "")
                )) ||
              (searchWord.startsWith("pubg_name:") &&
                String(user.pubg_name).includes(
                  searchWord.replace("pubg_name:", "")
                )) ||
              (searchWord.startsWith("email:") &&
                String(user.email).includes(
                  searchWord.replace("email:", "")
                )) ||
              (searchWord.startsWith("balance:") &&
                String(user.balance).includes(
                  searchWord.replace("balance:", "")
                )) ||
              (searchWord.startsWith("mobile_no:") &&
                String(user.mobile_no).includes(
                  searchWord.replace("mobile_no:", "")
                )) ||
              (searchWord.startsWith("created_at:") &&
                String(user.created_at).includes(
                  searchWord.replace("created_at:", "")
                )) ||
              (searchWord.startsWith("role:") &&
                String(user.role).includes(searchWord.replace("role:", ""))) ||
              (searchWord.startsWith("active:") &&
                String(user.active).includes(
                  searchWord.replace("active:", "")
                ));
            if (!condition) {
              condition = JSON.stringify(user).includes(searchWord);
            }
          }
          return condition;
        })
      : [];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        align: "center",
        style: {
          textTransform: "capitalize"
        }
      },
      {
        text: "Name",
        sort: true,
        align: "center",
        style: {
          textTransform: "capitalize"
        },
        formatter: (cell, row) => {
          return <span>{row.first_name + " " + row.last_name}</span>;
        }
      },
      {
        dataField: "pubg_name",
        text: "Game Name",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },
      {
        dataField: "email",
        align: "center",
        text: "Email",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },
      {
        dataField: "mobile_no",
        text: "Mobile No",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize"
        }
      },
      {
        dataField: "balance",
        align: "center",
        text: "Balance",
        sort: true,
        style: {
          textTransform: "capitalize "
        }
      },
      {
        dataField: "active",
        text: "Active",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize "
        },
        formatter: (cell, row) => {
          return <span>{cell == 1 ? <i class="fas fa-check" /> : ""}</span>;
        }
      },
      {
        dataField: "created_at",
        text: "Created At",
        align: "center",
        sort: true,
        style: {
          textTransform: "capitalize "
        }
      }
      //   {
      //     text: "Actions",
      //     align: "center",
      //     sort: true,
      //     formatter: (cell, row) => {
      //       return (
      //         <>
      //           <Link to={"/admin/useres/" + row.id} className="mr-4">
      //             <span className=" text-success ">
      //               <i className="far fa-edit" />
      //             </span>
      //           </Link>

      //           <span
      //             className="text-info cursor-pointer"
      //             onClick={() => {
      //               this.setState({ ...this.state, modelMatch: row });
      //             }}
      //           >
      //             <i className="far fa-eye" />
      //           </span>
      //         </>
      //       );
      //     }
      //   }
    ];
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-default  shadow card">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col md="3">
                      <h3 className="text-white mb-0">Users</h3>
                    </Col>
                    <Col md="9">
                      <Form className="navbar-search navbar-search-dark justify-content-end form-inline mr-3 d-none d-md-flex ml-lg-auto">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            onChange={e => {
                              this.setState({
                                ...this.state,
                                searchWord: e.target.value
                              });
                            }}
                            type="text"
                          />
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardHeader>

                <BootstrapTable
                  bordered={false}
                  classes="align-items-center table-dark table-flush table"
                  headerClasses="thead-dark table-header-center"
                  keyField="id"
                  data={users}
                  wrapperClasses="table-responsive"
                  columns={columns}
                  responsive
                  pagination={paginationFactory()}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
